import React, { Component } from 'react'
import {MainFooter} from '../components/MainFooter'
import { Header } from '../components/MainHeader/MainHeader'
import { MiniHeader } from '../components/MiniHeader/MiniHeader'
import ilu1 from '../assets/ilu1.svg'
import ilu2 from '../assets/ilu2.svg'
import ilu3 from '../assets/ilu3.svg'
import { connect } from 'react-redux'
import labelService from '../labelService'

class About extends Component {
    
    render() {

        return (
            <div className="home-root">
                <MiniHeader history={this.props.history}/>
                <Header />
                <div className="home-main" style={{ paddingTop: 100}}>
                    <div className="container">
                        <div className="row" style={{paddingTop: 100, paddingBottom: 100}} >
                            <div className="col s12 m12 l6  text-font">
                                <div className="center title-font" style={{paddingBottom: 40}}>
                                    <h4>
                                        {labelService.getLabel(this.props.selectedLanguage, 'ABOUT1_TITLE')} 
                                    </h4>
                                </div>
                                <div>
                                    <img src={ilu1} style={{width: 555}}/>
                                </div>
                            </div>
                            <div className="col s12 m12 l6  text-font" style={{paddingTop: 100}}>
                                {labelService.getLabel(this.props.selectedLanguage, 'ABOUT1_TEXT')} 
                            </div>
                        </div>
                        <div style={{paddingTop: 0.5, paddingBottom: 0.5, borderTop: '0.5px solid #009688'}}></div>
                        <div style={{paddingTop: 0.5, paddingBottom: 0.5, borderTop: '0.5px solid #009688'}}></div>
                        <div style={{paddingTop: 0.5, paddingBottom: 0.5, borderTop: '0.5px solid #009688'}}></div>
                        <div style={{paddingTop: 0.5, paddingBottom: 0.5, borderTop: '0.5px solid #009688'}}></div>
                        <div style={{paddingTop: 0.5, paddingBottom: 0.5, borderTop: '0.5px solid #009688'}}></div>
                        <div style={{paddingTop: 0.5, paddingBottom: 0.5, borderTop: '0.5px solid #009688'}}></div>
                        <div style={{paddingTop: 0.5, paddingBottom: 0.5, borderTop: '0.5px solid #009688'}}></div>

                        <div className="row" style={{paddingTop: 100, paddingBottom: 100}}>
                            <div className="col s12 m12 l6  text-font hide-on-med-and-down" style={{paddingTop: 100}}>
                                {labelService.getLabel(this.props.selectedLanguage, 'ABOUT2_TEXT')} 
                            </div>
                            <div className="col s12 m12 l6  text-font" >
                                <div className="center title-font" style={{paddingBottom: 40}}>
                                    <h4>
                                        {labelService.getLabel(this.props.selectedLanguage, 'ABOUT2_TITLE')} 
                                    </h4>
                                </div>
                                <div>
                                    <img src={ilu3} style={{width: 555}}/>
                                </div>
                            </div>
                            <div className="col s12 m12 l6  text-font hide-on-large-only" style={{paddingTop: 100}}>
                                {labelService.getLabel(this.props.selectedLanguage, 'ABOUT2_TEXT')} 
                            </div>
                        </div>
                        <div style={{paddingTop: 0.5, paddingBottom: 0.5, borderTop: '0.5px solid #009688'}}></div>
                        <div style={{paddingTop: 0.5, paddingBottom: 0.5, borderTop: '0.5px solid #009688'}}></div>
                        <div style={{paddingTop: 0.5, paddingBottom: 0.5, borderTop: '0.5px solid #009688'}}></div>
                        <div style={{paddingTop: 0.5, paddingBottom: 0.5, borderTop: '0.5px solid #009688'}}></div>
                        <div style={{paddingTop: 0.5, paddingBottom: 0.5, borderTop: '0.5px solid #009688'}}></div>
                        <div style={{paddingTop: 0.5, paddingBottom: 0.5, borderTop: '0.5px solid #009688'}}></div>
                        <div style={{paddingTop: 0.5, paddingBottom: 0.5, borderTop: '0.5px solid #009688'}}></div>
                        <div className="row" style={{paddingTop: 100, paddingBottom: 100,}}>
                            <div className="col s12 m12 l6  text-font">
                                <div className="center title-font" style={{paddingBottom: 40}}>
                                    <h4>
                                        {labelService.getLabel(this.props.selectedLanguage, 'ABOUT3_TITLE')} 
                                    </h4>
                                </div>
                                <div>
                                    <img src={ilu2} style={{width: 555}}/>
                                </div>
                            </div>
                            <div className="col s12 m12 l6  text-font" style={{paddingTop: 100}}>
                                {labelService.getLabel(this.props.selectedLanguage, 'ABOUT3_TEXT')} 
                            </div>
                        </div>
                    </div>
                </div>
                <MainFooter/>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const selectedLanguage = state.language;
    return {
        selectedLanguage
    };
}


const connectedNews = (connect(mapStateToProps)(About));
export {connectedNews as About};

