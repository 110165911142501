import React, { Component } from "react";
import { connect } from 'react-redux';
import "./Card.css";
import labelService from '../../labelService';

class CardCon extends Component {
  constructor(props){
        super(props);
  }

  onCardClick(id){
    this.props.history.push("/post/"+id);
  }

  converHtmlToText(html){
    if(!html) return '';
    var text = html.replace(/<[^>]+>/g, '');
  
    return text.length > 1 ? text.substring(0,2000) + '...' : '';
  }

  render() {
    //console.log(this.props.selectedLanguage);, backgroundColor : '#003845'
    //<a className="btn-news"></a>
    return (
      <div className="scc-cards-section2" style={{position: 'relative'}}>
        <div className="container" style={{display: 'flex', justifyContent: 'space-between', paddingBottom: 20}}>
          <div className="center-align" style={{paddingLeft: 30}}>
            <h4 className="scc-cards-section-title title-font" style={{marginTop: 0, color : 'black'}}>
            {labelService.getLabel(this.props.selectedLanguage, 'LATEST_NEWS')}</h4>
          </div>
          <div style={{paddingRight: 25}}>
            <div className="button-show-more" onClick={() => {this.props.history.push("/News");}} >
              <span className="center" style={{fontWeight: 500, fontSize: '1rem', color : 'black'}}>
                {labelService.getLabel(this.props.selectedLanguage, 'SHOW_MORE')}
              </span>
              <i className="material-icons " style={{fontSize : '1.4rem', color: '#000', marginTop: 3, paddingLeft: 5}}>forward</i>
            </div>
          </div>
        </div>
        <div className="container" >
          <div className="row">
            {this.props.data.map(post => (
              <div className="col s12 m12 l4" onClick={() => this.onCardClick(post.id)}>
                <div className="scc-cards_item">
                  <div className="scc-card" style={{ width: '100%'}}>
                    <div className="scc-card_image" style={post.img_path ? { backgroundImage: "url(" + post.img_path + ")"} : {backgroundRepeat: 'no-repeat'}}>
                      <div className="banner-container centered-container-col"  style={{ backgroundColor : 'rgba(47, 43, 43, 0.0)', borderBottom : '1px solid #003845'}}> </div>
                    </div>
                    <div className="scc-card_content cardNewsRightCol" style={{position : 'relative'}}>
                      <div className="cardHeadline title-font">{post.title}</div>
                     
                      <div className="text-font newsContent cardNewsContent" style={{fontSize: 18}}>
                        {this.converHtmlToText(post.text)}
                      </div>
                     
                    </div>
                  </div>
                  
                </div>
              </div>
            ))}
            
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
    const selectedLanguage = state.language;
    return {
        selectedLanguage
    };
}

const Card = (connect(mapStateToProps)(CardCon));
export default Card;