import axios from 'axios';
import React, { Component } from 'react';
import { Header } from '../../components/MainHeader/MainHeader';
import MainPost from '../../components/MainPost';
import { MiniHeader } from '../../components/MiniHeader/MiniHeader';
import {MainFooter}  from '../../components/MainFooter';
import  './Main.css';
import { connect } from 'react-redux';
import CoverNews from '../../components/CoverNews/CoverNews';
import Card from '../../components/CardsSection/Card';
import DecisionCards from '../../components/DecisionCards/DecisionCards';
import { userConstants } from '../../_constants/user-constants';
import labelService from '../../labelService';

class Main extends Component {

    constructor(props){
        super(props);

        this.state = {
            main_news : null,
            posts: [],
            decisions: []
        }
    }

    async componentDidMount(){
        this.getMainPost();
        this.getLastPosts();
        this.getLastDecisions();
    }

    async getMainPost(){
        let language = localStorage.getItem(userConstants.LANGUAGE_LOCAL);

        axios.post('/api/getMainNews', { language : language || this.props.selectedLanguage})
        .then(response => {
            let server_data = response.data;
           
            if(server_data.msg === 'succes'){
                this.setState({
                    main_news : { 
                        img_url : server_data.img_path, 
                        post_title: server_data.title, 
                        post_text: server_data.text,
                        id: server_data.id, 
                    }
                })
            }
           
        }
        ,error => {
        	this.setState({ loading: false, badLogin : true, badLoginMsg : 'Došlo je do greške prilikom prijavljivanja'});
            //console.log(error);
        });
    }

    async getLastPosts(){
        await this.setState({ loading: true});
        let language = localStorage.getItem(userConstants.LANGUAGE_LOCAL);

        axios.post('/api/getLastPosts', { language : language || this.props.selectedLanguage})
        .then(response => {
            let server_data = response.data;
            this.setState({ loading: false});
           
            if(server_data.msg === 'succes'){
                this.setState({
                   posts: server_data.posts
                })
            }
           
        }
        ,error => {
        	this.setState({ loading: false, badLogin : true, badLoginMsg : 'Došlo je do greške prilikom prijavljivanja'});
            //console.log(error);
        });
    }

    async getLastDecisions(){
        await this.setState({ loading: true});
        let language = localStorage.getItem(userConstants.LANGUAGE_LOCAL);

        axios.post('/api/getLastDecisions', { language : language || this.props.selectedLanguage})
        .then(response => {
            let server_data = response.data;
            this.setState({ loading: false});
           
            if(server_data.msg === 'succes'){
                this.setState({
                    decisions: server_data.decisions
                })
            }
           
        }
        ,error => {
        	this.setState({ loading: false, badLogin : true, badLoginMsg : 'Došlo je do greške prilikom prijavljivanja'});
            //console.log(error);
        });
    }

    render() {
        return (
            <div className="home-root">
                <MiniHeader history={this.props.history}/>
                <Header />
                <div className="home-main" style={{ paddingTop: 100}}>
                    <div className="main-banner">
                        <div className="banner-container centered-container-col"  style={{ backgroundColor : 'rgba(47, 43, 43, 0.2)'}} >
                            <div className="main-text">{labelService.getLabel(this.props.selectedLanguage, 'MAIN_TITLE')}</div>
                            <div className="main-sub-text">{labelService.getLabel(this.props.selectedLanguage, 'MAIN_SUBTITLE')}</div>
                        </div>
                    </div>
                    <div className="main-content" style={{ marginTop: 50}}>
                        
                        <div>
                            <CoverNews {...this.state.main_news} history={this.props.history}/>
                        </div>
                        <div>
                            <Card data={this.state.posts} history={this.props.history}></Card>
                        </div>
                        <div>
                            <DecisionCards data={this.state.decisions} history={this.props.history} />
                        </div>
                    </div>
                </div>
                <MainFooter/>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const selectedLanguage = state.language;
    return {
        selectedLanguage
    };
}

const connectedMain = (connect(mapStateToProps)(Main));
export {connectedMain as Main};
