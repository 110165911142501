import React, { Component } from "react";
import { connect } from "react-redux";
import { Header } from "../../components/MainHeader/MainHeader";
import { MiniHeader } from "../../components/MiniHeader/MiniHeader";
import labelService from "../../labelService";
import axios from "axios";
import skola from "../Main/skola.jpg";
import {MainFooter} from "../../components/MainFooter";
import './ExternLink.css'
import { userConstants } from "../../_constants/user-constants";
import InnerHtmlDiv from "../../components/InnerHtmlDiv";
import moment from "moment";
import noimage from '../../assets/noimage.png'

class ExternLink extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: "",
			textContent: "",
			images: [],
			docs: [],
			type: -1
		}
	}

	async componentDidMount() {
		this.getPostData();
	}

	async getPostData() {
		let language = localStorage.getItem(userConstants.LANGUAGE_LOCAL);
		let post_id = this.props.match.params.id;

		axios.post('/api/getPostData', { language: language || this.props.selectedLanguage, post_id })
			.then(response => {
				let server_data = response.data;

				if (server_data.msg === 'succes') {
					this.setState({
						img_path: server_data.post_data.img_path,
						title: server_data.post_data.title,
						textContent: server_data.post_data.text,
						images: server_data.post_data.images,
						docs: server_data.post_data.docs,
						type: server_data.post_data.type,
						date: moment(parseInt(server_data.post_data.dateunix)),
					})
				}

			}
				, error => {
					this.setState({ loading: false, badLogin: true, badLoginMsg: 'Došlo je do greške prilikom prijavljivanja' });
					//console.log(error);
				});
	}


	render() {
		return (
			<div className="home-root">
				<MiniHeader />
				<Header />
				<div className="home-main" style={{ paddingTop: 100 }}>
					<div className="main-content container " style={{ marginTop: 50 }}>
						{this.state.title &&
							<h2 className="newsTitle-txt">
								{this.state.title}
							</h2>
						}
						<div className="txt-content text-font">
							<InnerHtmlDiv className="" html_text={this.state.textContent} />
						</div>
					</div>
				</div>
				<MainFooter></MainFooter>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { loggedIn, user } = state.authentication;
	const selectedLanguage = state.language;
	return {
		loggedIn,
		user,
		selectedLanguage,
	};
}

const connectedLogin = connect(mapStateToProps)(ExternLink);
export { connectedLogin as ExternLink };
