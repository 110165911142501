export const EngLabels = {
    MAIN_TITLE                          : 'Ethics Committee',
    MAIN_SUBTITLE                       : 'The mission of the Ethics Committee is to promote, protect and promote academic integrity and prevent all forms of violations of academic integrity.',
    HOME                                : 'Home',
    ABOUT                               : 'About',
    NEWS                                : 'News',
    DECISIONS                           : 'Decisions',
    DOCUMENTS                           : 'Documents',
    SAZIVI                              : 'Calls',
    EXTERN_LINKS                        : 'Exter links',
    PUBLISH_DATE                        : 'Publish date',
    NO_DOCUMENTS                        : 'No documents',
    DOWNLOAD                            : 'Download',
    ADD_NEWS                            : 'Add news',
    ADD_CONGRESS                        : 'Add congress',
    TITLE                               : 'Title',
    TITLE_ENG                           : 'Title on english',
    POST_TEXT                           : 'Text of news',
    POST_TEXT_ENG                       : 'Text of news on english',
    PHOTOS                              : 'Photos',
    PUBLIC                              : 'Public',
    COVER                               : 'Cover',
    LATEST_NEWS                         : 'Latest news',
    SHOW_MORE                           : 'Show more',
    PREVIUOS                            : 'Previuos',
    NEXT                                : 'Next',
    SEARCH                              : 'Search',
    LOGIN                               : 'Login',
    LOGOUT                              : 'Log out',
    SAVE                                : 'Save',
    CHOOSE_IMAGE                        : 'Choose Image',
    ABOUT1_TITLE                        : "Etički komitet",
    ABOUT1_TEXT                         : "The Ethics Committee aims to preserve, promote, protect and promote academic integrity and prevent all forms of violations of academic integrity. The Ethics Committee is appointed and dismissed by the Government of Montenegro for a period of four years at the proposal of the state administration body responsible for education. The Ethics Committee has seven members consisting of prominent experts in the field of higher education and science from various scientific disciplines.",
    ABOUT2_TITLE                        : "Ethics Charter",
    ABOUT2_TEXT                         : "The Ethics Charter is a set of rules, principles and guidelines that promote human, intellectual and academic freedoms, as well as the responsible exercise of rights in scientific, teaching, artistic and professional work.",
    ABOUT3_TITLE                        : "Decision-making of the Ethics Committee",
    ABOUT3_TEXT                         : "The Ethics Committee decides on the violation of academic integrity. A proposal for establishing a violation of academic integrity may be submitted by any natural or legal person. The proposal with the explanation is submitted to the Ethics Board of the institution where the work was defended, the qualification was acquired, ie another form of violation of academic integrity was committed. The Ethics Committee forms a commission of three members from the same or a related scientific field. The Commission prepares a report with a proposal which the Ethics Committee submits for a statement to the person against whom the procedure has been initiated. The deadline for the statement of the person against whom the procedure on the report with the proposal has been initiated is 30 days. If the person does not declare himself within the deadline, the Ethics Committee makes a decision.",
    VISIBILITY                          : 'This website was developed with the financial support of the European Union and the Council of Europe, through the joint action <a href="https://rm.coe.int/hf24-leaflet-eng/16809a5a59" target="_blank">“Quality education for all”</a>, implemented from 2019 to 2022.',
    DISCLAIMER                          : 'The views expressed herein can in no way be taken to reflect the official opinion of either party.',
};
