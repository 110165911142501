import React, { Component } from "react";
import meet from "./meeting.jpg";
import "./CoverNews.css";
import noImage from '../../assets/Grb2.png'

export default class CoverNews extends Component {

  
  generateHtmlText = () => {
    if(!this.props.post_text) {
        return  <div>
                 </div>
    } else {
        return  <div  dangerouslySetInnerHTML={ {__html: this.converHtmlToText(this.props.post_text)} }></div>
    }
  }

  onPostClick(id){
    this.props.history.push("/post/"+id);

  }

  converHtmlToText(html){
    if(!html) return '-';
    var text = html.replace(/<[^>]+>/g, '');

    if (text && text.length > 5000)
      return text.substring(0, 5000) + "...";
    else 
      return text;
  }

  render() {
    const { img_url, post_title, id} = this.props;
    
    
    return (
      <div className="news-section">
        <div className="" style={{width: '80%', margin: 'auto'}}>
          <div className="row card " onClick={() => this.onPostClick(id)} style={{overflow: 'hidden', maxHeight: '500px !important', cursor: 'pointer', border: '1px solid rgba(47, 43, 43, 0.5)', boxShadow: '0 20px 40px -14px rgb(0 0 0 / 50%)'}}>
            <div className="col s12 m12 l6 main-news-image" style={img_url && {backgroundImage: "url(" + img_url + ")"}}>
                <div className="banner-container centered-container-col"  style={{ backgroundColor : 'rgba(47, 43, 43, 0.5)', height: '100%'}} >
                </div>
            </div>
            <div className="col s12 m12 l6 coverNewsRightCol" style={{paddingLeft: 25, paddingRight: 25, position : 'relative'}}>
              <h2 className="titleNews title-font">{post_title}</h2>  
              <div className=" newsContent coverNewsContent text-font">
                {this.generateHtmlText()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

