import axios from 'axios';
import React, { Component } from 'react';
import { Header } from '../../components/MainHeader/MainHeader';
import { MiniHeader } from '../../components/MiniHeader/MiniHeader';
import { connect } from 'react-redux';
import {MainFooter} from '../../components/MainFooter';
import { userConstants } from '../../_constants/user-constants';
import InnerHtmlDiv from '../../components/InnerHtmlDiv';
import Posts from '../../components/Posts';

class ExternLinks extends Component {

    constructor(props){
        super(props);

        this.state = {
            text: '',
            title: '',
            posts: []
        }
    }

    
    async componentDidMount(){
        await this.getExternLinks();
    }

    async getExternLinks(){
        let language = localStorage.getItem(userConstants.LANGUAGE_LOCAL);

        axios.post('/api/getExternLinks', { language : language || this.props.selectedLanguage})
        .then(response => {
            let server_data = response.data;
 
            if(server_data.msg === 'succes'){
                this.setState({
                    posts: server_data.posts,
                    /*text : server_data.text,
                    title : server_data.title*/
                })
            }
        }
        ,error => {
            //console.log(error);
        });
    }


    render() {

        return (
            <div className="home-root">
                <MiniHeader history={this.props.history}/>
                <Header />
                <div className="home-main" style={{ paddingTop: 100}}>
                    <div className="container">
                        <Posts selectedLanguage={this.props.selectedLanguage}  name="Linkovi" type={5}  history={this.props.history} />
                        {/*<div style={{marginTop: 50}}>
                            {this.state.title &&
                            <div className="pages-padd ">
                                <div className="row ">
                                    <h2 className="newsTitle-txt">
                                    {this.state.title}
                                    </h2>
                                </div>
                            </div>}
                            <InnerHtmlDiv html_text={this.state.text}/>
                            </div>*/}
                    </div>
                </div>
                <MainFooter/>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { loggedIn, user } = state.authentication;
    const selectedLanguage = state.language;
    return {
        loggedIn, user, selectedLanguage
    };
}


const connectedExternLinks = (connect(mapStateToProps)(ExternLinks));
export {connectedExternLinks as ExternLinks};
