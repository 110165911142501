
//this component consists of several subcomponents
import React, { useEffect, Fragment } from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import { Provider} from 'react-redux';
import store from './_helpers/store.js';
import {Login} from './Pages/Login';
import  {Main}  from './Pages/Main/Main';
import {Admin} from './Pages/Admin';
import {Post} from './Pages/Post/Post';
import {News} from './Pages/News';
import {Decisions} from './Pages/Decisions';
import {Documents} from './Pages/Documents';
import {ExternLinks} from './Pages/ExternLinks/ExternLinks';
import {ExternLink} from './Pages/ExternLinks/ExternLink';
import {MainCongress} from './Pages/Congress/MainCongress';
import {Congress} from './Pages/Congress/Congress';
import {About} from './Pages/About.js';

function App() {
  return (
    <Provider store={store}>
          <Router>
              <Fragment>
                  <div className="scc-container">
                      <Switch>
                          <Route path="/" exact component={Main}/>
                          <Route path="/login" component={Login} />
                          <Route path="/admin"  component={Admin}/>
                          <Route path="/post/:id"  component={Post}/>
                          <Route path="/externlink/:id"  component={ExternLink}/>
                          <Route path="/News"  component={News}/>
                          <Route path="/Decisions"  component={Decisions}/>
                          <Route path="/Documents"  component={Documents}/>
                          <Route path="/MainCongress"  component={MainCongress}/>
                          <Route path="/Congress/:id"  component={Congress}/>
                          <Route path="/ExternLinks"  component={ExternLinks}/>
                          <Route path="/About"  component={About}/>
                      </Switch>
                  </div>
              </Fragment>
          </Router>
      </Provider>
  );
}

export default App;
