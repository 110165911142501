import React from 'react'
function solveCenter(html){
    if(!html) return '-';
    var text = html;
    if(html.includes("text-align:none;")){
        var text = html.replace('text-align:none;', 'text-align:center;');

    }

    return text;
}
export default function InnerHtmlDiv({html_text, className}) {
    if(!html_text) {
        return  <div></div>
    } else {
        return  <div  dangerouslySetInnerHTML={ {__html: solveCenter(html_text) } } style={{ color: '#555'}} className={className || 'text-font'}></div>
    }
}
