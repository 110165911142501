import React, { Component } from "react";
import { connect } from "react-redux";
import { Header } from "../../components/MainHeader/MainHeader";
import { MiniHeader } from "../../components/MiniHeader/MiniHeader";
import labelService from "../../labelService";
import axios from "axios";
import skola from "../Main/skola.jpg";
import {MainFooter} from "../../components/MainFooter";
import './Post.css'
import { userConstants } from "../../_constants/user-constants";
import InnerHtmlDiv from "../../components/InnerHtmlDiv";
import moment from "moment";
import noimage from '../../assets/noimage.png'

class Post extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: "",
			textContent: "",
			images: [],
			docs: [],
			type: -1
		}
	}

	async componentDidMount() {
		this.getPostData();
	}

	async getPostData() {
		let language = localStorage.getItem(userConstants.LANGUAGE_LOCAL);
		let post_id = this.props.match.params.id;

		axios.post('/api/getPostData', { language: language || this.props.selectedLanguage, post_id })
			.then(response => {
				let server_data = response.data;

				if (server_data.msg === 'succes') {
					this.setState({
						img_path: server_data.post_data.img_path,
						title: server_data.post_data.title,
						textContent: server_data.post_data.text,
						images: server_data.post_data.images,
						docs: server_data.post_data.docs,
						type: server_data.post_data.type,
						date: moment(parseInt(server_data.post_data.dateunix)),
					})
				}

			}
				, error => {
					this.setState({ loading: false, badLogin: true, badLoginMsg: 'Došlo je do greške prilikom prijavljivanja' });
					//console.log(error);
				});
	}


	render() {

		return (
			<div className="home-root">
				<MiniHeader />
				<Header />
				<div className="home-main" style={{ paddingTop: 100 }}>
					<div className="main-content container text-font" style={{ marginTop: 50, width: '80%' }}>
						{this.state.title &&						
							<h2 className="newsTitle-txt pages-padd">
								{this.state.title}
							</h2>
						
						}
						{this.state.type === 1 && <div className="pages-padd" style={{ borderBottom: '1px solid gray', paddingBottom: 20 }}>
							<div>

								<img className="" style={{ width: '100%' }}
									src={this.state.img_path}
								/>
							</div>
							<div style={{ paddingTop: 10, fontWeight: '600', fontSize: 17 }} className="text-font">
								<span style={{}}>{labelService.getLabel(this.props.selectedLanguage, 'PUBLISH_DATE') + ':'} </span>

								<span style={{ color: '#258a71' }}>
									{moment(this.state.date).format('HH:mm DD/MM/YYYY')}
								</span>
							</div>
						</div>
						}
						<div className="pages-padd" style={{ marginBottom: '2.674rem', marginTop: 0 }} >
							<div className="txt-content">
								<InnerHtmlDiv className="" html_text={this.state.textContent} />
							</div>
						</div>
						<div className="" style={{ marginBottom: '2.674rem' }}>
							<div className="doc-block row">
								<div style={{ flex: 1 }}>
									<h3 style={{ margin: 0 }}>{labelService.getLabel(this.props.selectedLanguage, 'DOCUMENTS')}</h3>
								</div>
								<div className="doc-line-block">
									<div className="line"></div>
								</div>
							</div>
							{this.state.docs && this.state.docs.map(doc => (
								<div className=" row">
									<div className="doc-font col s12 m10 l10">
										{doc.docsid}
									</div>
									<div className="col s12 m2 l2">
										<div className="input-field" >
											<a target="_blank"
												href={userConstants.URL_API + '/uploads/' + doc.docsid}
												className="waves-effect waves-light btn darken-4"
												style={{ width: 140 }}
												onClick={this.createNewPost}
											>
												{labelService.getLabel(this.props.selectedLanguage, 'DOWNLOAD')}
												<i className="material-icons left" style={{ fontSize: '1.2rem', color: '#fff', }}>download</i>
											</a>
										</div>
									</div>
									
								</div>
							))}
							{!this.state.docs || this.state.docs.length <= 0 &&
								<div style={{ paddingTop: 10, paddingLeft: 50 }}>
									<span style={{ fontSize: 20 }}>{labelService.getLabel(this.props.selectedLanguage, 'NO_DOCUMENTS')}</span>
								</div>
							}
						</div>
					</div>
				</div>
				<MainFooter></MainFooter>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { loggedIn, user } = state.authentication;
	const selectedLanguage = state.language;
	return {
		loggedIn,
		user,
		selectedLanguage,
	};
}

const connectedLogin = connect(mapStateToProps)(Post);
export { connectedLogin as Post };
