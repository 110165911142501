import { userConstants } from '../_constants/user-constants';

const initialState = { loggedIn: false, user : ''};

export default (state = initialState, action) => {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {loggedIn: false, user: action.user};
        case userConstants.LOGIN_SUCCESS:
            return {loggedIn: true, user: action.user};
        case userConstants.LOGIN_FAILURE:
            return {loggedIn: false, user : ''};
        case userConstants.LOGOUT:
            return {loggedIn: false, user : ''};
        default:
            return state;
    }
}