import axios from 'axios';
import React, { Component } from 'react'
import ReactPaginate from 'react-paginate';
import moment from 'moment'
import InnerHtmlDiv from './InnerHtmlDiv';
import { userConstants } from '../_constants/user-constants';
import labelService from '../labelService';
import noimage from '../assets/noimage.png'
import balance from '../assets/balance2.svg'

export default class Posts extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading : false,
            news: [],
            edit: false,
            selected_news : null,
            perPage : 10,
            offset: 0,
            pageCount: 0,
            search: '',
            type: 1
        }
    }

    async componentDidMount(){
        await this.getAllNews();
    }

    async componentDidUpdate(prevsta){
        if(this.state.type != this.props.type){
            await this.setState({ edit : false, type: this.props.type});
            this.getAllNews();
        }
    }

    async getAllNews(){

        await this.setState({ loading: true});
        let language = localStorage.getItem(userConstants.LANGUAGE_LOCAL);

        axios.post('/api/getAllPublishNews', {
            limit: this.state.perPage, 
            offset: this.state.offset, 
            type: this.state.type, 
            language : language || this.props.selectedLanguage,
            search: this.state.search,
        })
        .then(response => {
            let server_data = response.data;
            this.setState({ loading: false});

            if(server_data.msg === 'succes'){
                setTimeout(() => {
                    this.setState({
                        news : server_data.news,
                        pageCount: server_data.total_count / this.state.perPage,
                    });
                }, 100);
            }
        }
        ,error => {
            this.setState({ loading: false});
        });
    }

    generateRows(){
        const { news } = this.state;
        
        let typeClass = this.props.type === 1 ? 'l12' : 'l8';
        return (
            <div style={{paddingTop: 20}}>
                <div className="row " style={{ maxWidth: 420}}>
                    <div className="input-field col s8 mtp0">
                        <input id="search" type="text" name="search" value={this.state.search} onChange={(e) => { this.setState({ search : e.target.value})}}/>
                        <label htmlFor="search">{labelService.getLabel(this.props.selectedLanguage, 'SEARCH')}</label>
                        
                    </div>
                    <div className="input-field col s4 mtp0" style={{paddingTop: 5}}>
                        <a  
                            href="javascript:void(0)" 
                            className="waves-effect waves-light btn darken-4"
                            onClick={(e) => { this.getAllNews() } }
                            >
                            {labelService.getLabel(this.props.selectedLanguage, 'SEARCH')}
                        </a>
                    </div>
                    
                </div>
                <div className="row">
                    <div className="" style={{width: '100%', minHeight: 600, marginTop: 40, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        {news.map(news_item => 
                            <div className={"row card post-card" + typeClass} onClick={(e) => this.openNews(e, news_item)} style={{ width: '100%',padding: 15, minHeight: this.state.type === 1 ? 150 : 75, marginLeft: 0, marginTop: 30 }}>
                                {this.state.type === 2 &&
                                <div className="card" style={{display: 'flex', justifyContent: 'center',alignItems: 'center',position: 'absolute', left: '50%', top: -35, padding: 8, border: '1px solid #eeeeee', borderRadius: 45, backgroundColor: '#fff'}}>
                                        <img src={balance} style={{width: 30, height: 30}}/>
                                </div>}
                                {this.state.type === 3 &&
                                <div className="card" style={{width: 45, height: 45,display: 'flex', justifyContent: 'center',alignItems: 'center',position: 'absolute', left: '50%', top: -25, padding: 5, border: '1px solid #eeeeee', borderRadius: 45, backgroundColor: '#fff'}}>
                                        <i className="material-icons " style={{fontSize : '1.7rem', color: '#09375c'}}>description</i>
                                </div>}
                                {this.state.type === 5 &&
                                <div className="card" style={{width: 45, height: 45,display: 'flex', justifyContent: 'center',alignItems: 'center',position: 'absolute', left: '50%', top: -25, padding: 5, border: '1px solid #eeeeee', borderRadius: 45, backgroundColor: '#fff'}}>
                                        <i className="material-icons " style={{fontSize : '1.7rem', color: '#09375c'}}>link</i>
                                </div>
                                }

                                {
                                    this.state.type === 1 &&
                                    <div className="col s12 l3">
                                        <img 
                                            src={news_item.imagesid ? userConstants.URL_API + '/uploads/'+ news_item.imagesid : noimage} 
                                            style={{ height: 150, borderRadius: 2, width: '100%'}}
                                        />
                                    </div>
                                }
                                <div className="col s12 l9" style={{paddingLeft: 20, paddingTop: 5, position: 'relative'}}>
                                    
                                    <div className="hide-on-large-only" style={{ fontSize: 11, fontWeight : '600', color: '#09375c',fontFamily: 'Poppins, sans-serif'}}>
                                        {moment(parseInt(news_item.dateunix) ).format('HH:mm DD/MM/YYYY')}
                                    </div>
                                    {(this.state.type === 1 || this.state.type === 2 || this.state.type === 5) && 
                                    <div style={{paddingTop: 10}}>
                                        
                                        <h3 style={{fontSize: '1.125rem', fontWeight: '600', fontFamily: 'Poppins, sans-serif', marginTop: 0, padding: 0}}>
                                            <a className="post-title title-font"  style={{ color: '#09375c'}}>
                                                {news_item.title}
                                            </a>
                                        </h3>
                                    </div>}
                                    <div style={{padding: 4,}}>
                                        <InnerHtmlDiv html_text={this.converHtmlToText(news_item.text)} className="posts-card-font" />
                                    </div>
                                    
                                </div>
                                <span className="hide-on-med-and-down" style={{ position: 'absolute', right: 10, top: 20, fontSize: 12, fontWeight : '600', color: '#09375c',fontFamily: 'Poppins, sans-serif'}}>
                                    {moment(parseInt(news_item.dateunix) ).format('HH:mm DD/MM/YYYY')}
                                </span>
                                
                            </div>
                        )}
                    </div>
                </div>
                <div className="row center" style={{width: '100%'}}>
                    {this.state.pageCount > 0 && 
                        <ReactPaginate
                            previousLabel={labelService.getLabel(this.props.selectedLanguage,'PREVIUOS')}
                            nextLabel={labelService.getLabel(this.props.selectedLanguage,'NEXT')}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={8}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                            />}
                </div>
            </div>
            
        )
    }
    
    searchPosts = () => {

    }

    converHtmlToText(html){
        if(!html) return '-';
        var text = html.replace(/<[^>]+>/g, '');

        return text.substring(0,200);
    }

    handlePageClick = async (data) => {
        let selected = data.selected;
        let offset = Math.ceil(selected * this.state.perPage);
        await this.setState({ offset});

        this.getAllNews();
    }

    generateTitle(news_item){
        const { selectedLanguage } = this.props;

        if(selectedLanguage === 'MNE') return news_item.title || 'Prazno';
        else return news_item.title_eng || 'Empty';
    }
    
    openNews = async (e, news_item) => {
        e.preventDefault();
        if(this.props.type == 5) 
            this.props.history.push("/externlink/" + news_item.id);
        else 
            this.props.history.push("/post/" + news_item.id);
        //await this.setState({ edit: true, selected_news: news_item});
    }

    closeNews = async() => {
        await this.setState({ edit: false, selected_news: []});

        this.getAllNews();
    }

    render() {
        const content = this.generateRows()
        return (
            <div>
                {content}
            </div>
        )
    }
}
