import React, { Component } from "react";
import "./DecCard.css";
import balance from './balance2.svg'
export default class DecisionCards extends Component {

  onCardClick(id){
    this.props.history.push("/post/"+id);
  }

  converHtmlToText(html){
    if(!html) return '';
    var text = html.replace(/<[^>]+>/g, '');
  
    return text.length > 1 ? text.length >= 200 ? text.substring(0,200) + '...' : text : '';
  }

  render() {
    return (
      <div className="scc-cards-section" style={{position: 'relative', backgroundColor: '#fff'}}>
        <div className="container" style={{display: 'flex', justifyContent: 'space-between', paddingBottom: 20}}>
          <div className="center-align" style={{paddingLeft: 30}}>
            <h4 className="scc-cards-section-title title-font" style={{marginTop: 0}}>Odluke</h4>
          </div>
          <div style={{paddingRight: 25}}>
            <div className="button-show-more-d" onClick={() => {this.props.history.push("/Decisions");}} >
              <span className="center" style={{fontWeight: 500, fontSize: '1rem'}}>
                {'Prikaži više'}
              </span>
              <i className="material-icons " style={{fontSize : '1.4rem', color: '#09375c', marginTop: 3, paddingLeft: 5}}>forward</i>
            </div>
          </div>
        </div>
        <div className="container" >
          <div className="row" style={{width : '80%'}}>
            {this.props.data.map(post => (
              <div className="col s12 m6 l4" onClick={() => this.onCardClick(post.id)} >
                <li className="scc-cards_item">
                  <div className="scc-card" style={{ width: '100%', border: '1px solid #d0d0d0', borderBottom: 0}}>
                    <div className="" style={{position: 'relative'}}>
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 20}}>
                        <div className="card" style={{display: 'flex', justifyContent: 'center',alignItems: 'center', padding: 15, border: '1px solid #eeeeee',borderRadius: 45, backgroundColor: '#fff'}}>
                          <img src={balance} style={{width: 45, height: 45}}/>
                        </div>
                      </div>
                    </div>
                    <div className="scc-card_content-dec" style={{maxHeight : '300px !important'}}>
                      <div className="cardDesHeadline title-font">{post.title}</div>
                      <a className="btn-news"></a>
                    </div>
                  </div>
                </li>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
