import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header } from '../../components/MainHeader/MainHeader';
import { MiniHeader } from '../../components/MiniHeader/MiniHeader';
import labelService from '../../labelService';
import axios from 'axios'
import { userConstants } from '../../_constants/user-constants';
import InnerHtmlDiv from '../../components/InnerHtmlDiv';
import { Link } from 'react-router-dom';
import {MainFooter} from '../../components/MainFooter';

class Congress extends Component {

    constructor(props){
        super(props);

        this.state = {
            title: '',
            text: '',
            id: 0,
            data: [],
            current_cong: null
        }
    }

    async componentDidMount(){
        await this.getMainCongress();
    }

    async componentDidUpdate(){
        let cong_id = this.props.match.params.id;
        return;
        if(cong_id && cong_id != this.state.id){
            await this.setState({ id : cong_id});

            if(cong_id == 0 )
                await this.getMainCongress();
            else 
                await this.getCongressById(cong_id)
        }
    }

    async getMainCongress(){
        let language = localStorage.getItem(userConstants.LANGUAGE_LOCAL);
        let {id, data } = this.state;
        let cong = this.state.current_cong;
        if(id > 0){
            cong = data.filter(a => a.id == id)[0];
        }

        axios.post('/api/getAllPublicCongress', { language : language || this.props.selectedLanguage, cong})
        .then(response => {
            let server_data = response.data;
            if(!server_data.data || server_data.data.length <=0 ) return;
 
            if(server_data.msg === 'succes'){

                let data = [...server_data.data];
                let lastCongress = {title: data[0].title, text: data[0].text, dateunix: data[0].dateunix};
                data.shift()
                this.setState({
                    ...lastCongress,
                    current_cong : lastCongress,
                    data 
                })
            }
           
        }
        ,error => {
            //console.log(error);
        });
    }

    async getCongressById(id){
        let language = localStorage.getItem(userConstants.LANGUAGE_LOCAL);

        axios.post('/api/getCongressById', { language : language || this.props.selectedLanguage, id})
        .then(response => {
            let server_data = response.data;
           
            if(server_data.msg === 'succes'){
                let data = [...server_data.data];
                if(!data || data.length <=0 ) return;

                let lastCongress = {title: data[0].title, text: data[0].text, dateunix: data[0].dateunix};
                data.shift()
                this.setState({
                    ...lastCongress,
                    current_cong : lastCongress,
                    data 
                })
            }
           
        }
        ,error => {
            //console.log(error);
        });
    }
    
    generateTable(){
        return (
            <div className=" " style={{marginTop: 50, position: 'relative'}}>
                <div className="table-container" style={{borderRadius: 12}}>
                    <div className="table-header" style={{display: 'flex', alignItems: 'center', paddingLeft: 20}}>
                        <span>Prethodni sazivi</span>
                    </div>
                    <div className="table-content">
                        {this.generateRows()}
                    </div>
                </div>
            </div>
        )
    }

    generateRows(){
        let { data } = this.state;
        if(!data || data.length <= 0) return (
            <div style={{display: 'flex', alignItems: 'center', borderRadius: 12}}>
                <span  style={{paddingLeft: 20,color: 'gray'}}>Nema prethodnih saziva</span>
            </div>
        )

        return data.map((congress, i) =>
            <div style={{display: 'flex', alignItems: 'center',borderRadius: i === data.length - 1 ? 12 : 0}}>
                <Link to={'/Congress/'+congress.id} style={{paddingLeft: 20, fontWeight: 500}}>{congress.title}</Link>
            </div>
        )
    }

    getCurrentCongress(){
        
    }

    render() {
        const tableJSX = this.generateTable();

        return (
            <div className="home-root">
                <MiniHeader />
                <Header />
                <div className="home-main" style={{ paddingTop: 100}}>
                    <div className="container">
                        <div>
                            <h2>{this.state.title}</h2>
                        </div>
                        <div style={{marginTop: 50}}>
                            <InnerHtmlDiv className="" html_text={this.state.text}/>
                        </div>
                        <div>
                            {tableJSX}
                        </div>
                    </div>
                </div>
                <MainFooter/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.authentication;
    const selectedLanguage = state.language;
    return {
        loggedIn, user, selectedLanguage
    };
}


const connectedCongress = (connect(mapStateToProps)(Congress));
export {connectedCongress as MainCongress};

