
const INITIAL_STATE = 'MNE';

export default (state = INITIAL_STATE, action) => {
    
    switch(action.type) {
     case 'SELECT_LANGUAGE':
       
      return action.payload;
     default: 
      return state;
    }
};

