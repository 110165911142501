import React, { Component } from 'react'
import  { connect } from 'react-redux';
import  lblSrvc from '../../labelService';
import './header.css';
import { userConstants } from "../../_constants/user-constants";
import {Link} from 'react-router-dom';
import { failure} from '../../_actions/actions';
import  { selectLanguage } from '../../_actions/languageActions';
import logo from './Grb2.png'

class MainHeader extends Component {

    constructor(props){
        super(props);
        this.dropDown = React.createRef();
        this.state = {
            icon_name: 'mne',
            username: '',
            loggedIn: false,
            isAdmin: false
        }
    }

    componentDidMount() {
        const M = window.M;
        
        let sidenav = document.querySelector('#slide-out');
        M.Sidenav.init(sidenav, {});
    }

    render() {
        return (
            <header className="shadow" style={{top: 50}}>
                <nav className="nav-wrapper  navbar-transition cool-navbar z-depth-0" style={{height : 100, backgroundColor: '#fff', transition: '0.3s'}}>
                    <div className="container" style={{maxWidth : '100%', width: '100% !important'}}>
                        <div className="">
                            <a className="sidenav-trigger" data-target="slide-out" >
                                <i className="material-icons blue-text" >menu</i>
                            </a> 
                        </div>
                        <div className="nav-wrapper row">
                            <div className="col s3 centered-container" style={{ marginLeft : 0, marginTop : 0}}>
                                 <a href="javascript:void(0)" className="brand-logo">
                                    <img src={logo} width="170" className="scc-header-image"/>
                                </a>
                            </div>
                            <div className="right hide-on-med-and-down col s9 right-container" style={{marginRight : 10}}>
                                <ul className="right">
                                    <li> <Link className="openSansHeader" to="/" >{lblSrvc.getLabel(this.props.selectedLanguage, 'HOME')}</Link></li>
                                    <li> <Link className="openSansHeader" to="/News" >{lblSrvc.getLabel(this.props.selectedLanguage, 'NEWS')}</Link></li>
                                    <li> <Link className="openSansHeader" to="/Decisions" >{lblSrvc.getLabel(this.props.selectedLanguage, 'DECISIONS')}</Link></li>
                                    <li> <Link className="openSansHeader" to="/Documents" >{lblSrvc.getLabel(this.props.selectedLanguage, 'DOCUMENTS')}</Link></li>
                                    <li> <Link className="openSansHeader" to="/MainCongress" >{lblSrvc.getLabel(this.props.selectedLanguage, 'SAZIVI')}</Link></li>
                                    <li> <Link className="openSansHeader" to="/ExternLinks" >{lblSrvc.getLabel(this.props.selectedLanguage, 'EXTERN_LINKS')}</Link></li>
                                    <li> <Link className="openSansHeader" to="/About" >{lblSrvc.getLabel(this.props.selectedLanguage, 'ABOUT')}</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
                
                <ul id="slide-out" className="sidenav" style={{ backgroundColor: '#fff'}}>
                    <li> <Link className="openSansHeader strong" to="/" >{lblSrvc.getLabel(this.props.selectedLanguage, 'HOME')}</Link></li>
                    <li> <Link className="openSansHeader strong" to="/News" >{lblSrvc.getLabel(this.props.selectedLanguage, 'NEWS')}</Link></li>
                    <li> <Link className="openSansHeader strong" to="/Decisions" >{lblSrvc.getLabel(this.props.selectedLanguage, 'DECISIONS')}</Link></li>
                    <li> <Link className="openSansHeader strong" to="/Documents" >{lblSrvc.getLabel(this.props.selectedLanguage, 'DOCUMENTS')}</Link></li>
                    <li> <Link className="openSansHeader strong" to="/MainCongress" >{lblSrvc.getLabel(this.props.selectedLanguage, 'SAZIVI')}</Link></li>
                    <li> <Link className="openSansHeader strong" to="/ExternLinks" >{lblSrvc.getLabel(this.props.selectedLanguage, 'EXTERN_LINKS')}</Link></li>
                    <li> <Link className="openSansHeader strong" to="/About" >{lblSrvc.getLabel(this.props.selectedLanguage, 'ABOUT')}</Link></li>

                </ul>
            </header>
        )
    }
}

const mapStateToProps = state => ({
    loggedIn: state.authentication.loggedIn,
    username: state.authentication.user, 
    selectedLanguage: state.language,
});


const connectedHeader = (connect(mapStateToProps, { selectLanguage, failure })(MainHeader));
export {connectedHeader as Header};