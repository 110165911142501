import axios from 'axios';
import React, { Component } from 'react';
import labelService from '../../labelService';
import { userConstants } from '../../_constants/user-constants';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {stateFromHTML} from 'draft-js-import-html';
import Spiner from '../Spiner/Spiner';
import DatePicker from "react-datepicker";
import {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import sr from 'date-fns/locale/sr-Latn';
registerLocale('sr-Latn', sr);

class PostForm extends Component {
    constructor(props){
        super(props);

        this.state = {
            main_post : false,
            post_title: '',
            post_title_eng: '',
            post_public: false,
            imageFile: 'undefined',
            imageFiles: [],
            imageBase64: null,
            editorState : EditorState.createEmpty(),
            editorStateEng : EditorState.createEmpty(),
            uploadLoading: false,
            docs: [],
            removeImages: [],
            removeDocs: [],
            type: 0,
            dateBegin: new Date()
        }
    }

    async componentDidMount(){
        const M = window.M;
        
        let elem = document.querySelectorAll('.modal');
        M.Modal.init(elem[0], {startingTop : '50%'});

        await this.getPostData();
        await this.getImagesAndDocs();
        M.updateTextFields();
    }

    async getPostData(){
        const { data} = this.props;

        if(!data) return;

        const contentBlock = htmlToDraft(data.text);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);

        await this.setState({
            post_title: data.title,
            post_title_eng: data.title_eng,
           // editorState: EditorState.createWithContent(stateFromHTML(data.text)),
            editorState : editorState, 
            editorStateEng: EditorState.createWithContent(stateFromHTML(data.text_eng)),
            post_public: data.publish,
            main_post: data.main,
            dateBegin : data.dateunix ? new Date(parseInt(data.dateunix)) : new Date()

        });
    }

    async getImagesAndDocs(){
        const { data } = this.props;
        if(!data) return;

        axios.post('/api/getImagesAndDocs', { newsid: data.id, type: this.props.type })
        .then(response => {
            let server_data = response.data;
            this.setState({ loading: false});

            if(server_data.msg === 'succes'){
               this.setState({
                    docs: server_data.documents,
                    imageFiles: server_data.images,
                });
            }
        }
        ,error => {
            //console.log(error);
        });

    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    }

    onEditorStateEngChange = (editorStateEng) => {
        this.setState({
            editorStateEng,
        });
    }

    updatePost = async () => {
        let { post_title, editorState, editorStateEng, post_title_eng, main_post, post_public, imageFiles, dateBegin} = this.state; 
        let token = localStorage.getItem(userConstants.MEIS_EDU_TOKEN);
        await this.setState({uploadLoading: true});

        const editorHTML = this.getContentHtml(editorState);;
        const editorEngHTML = this.getContentHtml(editorStateEng);
        const coverImage = imageFiles.filter(img => img.cover);

        axios({
            method: 'post',
            url: '/api/updateNews',
            data: {editorHTML : editorHTML, editorEngHTML : editorEngHTML},
            params: { 
                news_id : this.props.data.id,
                post_title, 
                post_title_eng, 
                /*editorHTML,
                editorEngHTML,*/
                main: main_post ? 1 : 0,
                publish: post_public ? 1 : 0,
                coverImage : coverImage && coverImage.length > 0 && coverImage[0].name,
                dateunix: moment(dateBegin).unix() * 1000
            },
            headers: {
                Authorization: `Bearer ${token}` 
            }
            })
            .then( (response) => {
                if(response.data.msg === 'ok'){
                    setTimeout(() => {
                        this.setState({uploadLoading: false});
                        this.props.onGoBack();
                    }, 250);
                    
                }
            })
            .catch( (err) => {
                this.setState({uploadLoading: false});
                //console.log(err);
        });
    }

    getContentHtml(editorState){
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const markup = draftToHtml(
            rawContentState, 
            {
                trigger: '#',
                separator: ' ',
              }, 
            true, 
        );
        
        return markup;
    }

    onChooseImage = async (event) => {
        let files = event.target.files;
        let hasCoverImage = this.state.imageFiles && this.state.imageFiles.length > 0;

        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            await this.readImageFile(file, !hasCoverImage && i == 0);
        }
      
        this.uploadFiles(hasCoverImage);
    }

    readImageFile = (file, isCover) => new Promise(res => {
        let reader = new FileReader();
        const newFileName = Date.now() + '_' + file.name;

        reader.onloadend =  () => {
            const imageBase64 =  reader.result;
            this.setState({
                imageFiles: [
                    ...this.state.imageFiles,
                    { 
                        imageFile: this.renameFile(file, newFileName), 
                        imageBase64, 
                        name: newFileName,
                        upload: true,
                        cover : isCover
                    }
                ]
            });
            res();
        }
        reader.readAsDataURL(file);
    })
    
    onChooseDoc = async (event) => {
        let files = event.target.files;
        let newDocs = [];
        files && [...files].forEach(file => {
            const newFileName = Date.now() + '_' + file.name;

            newDocs.push({ 
                file : this.renameFile(file, newFileName), 
                name: newFileName,
                upload: true
            });
        });

        await this.setState({ docs: [...this.state.docs, ...newDocs]});
        this.uploadFiles();
    }

    renameFile(originalFile, newName) {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }

    uploadFiles = async (hasCoverImage) => {
        let { imageFiles, docs } = this.state;
        let token = localStorage.getItem(userConstants.MEIS_EDU_TOKEN);

        const data = new FormData();
        imageFiles.forEach(async img => img.upload && data.append("files", img.imageFile));
        docs.forEach(async doc => doc.upload  &&  data.append("files", doc.file));

        await this.setState({
            uploadLoading: true,
            imageFiles : [...imageFiles].map(image => { 
                image.upload = false;
                return image;
            })
        });

        axios({
            method: 'post',
            url: '/api/uploadFiles',
            data: data,
            params: { 
                news_id : this.props.data.id,
                hasCoverImage : hasCoverImage ? true : false
            },
            headers: {
                'Content-Type': 'multipart/form-data', 
                Authorization: `Bearer ${token}` 
            }
            })
            .then( (response) => {
                this.setState({uploadLoading: false});

                if(response.data.msg === 'ok'){
                    setTimeout(() => {
                        this.setState({ uploadLoading: false });
                    }, 250);
                }
            })
            .catch( (err) => {
                this.setState({uploadLoading: false});
                //console.log(err);
        });
    }

    generateImagesRows(){

        return this.state.imageFiles.map(image =>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <label style={{paddingTop: 10}}>
                    <input type="checkbox" className="filled-in" checked={image.cover} onChange={() => !image.cover && this.setImageCover(image.name)} />
                    <span></span>
                </label>                 
                <img src={image.imageBase64 ? image.imageBase64 : userConstants.URL_API + '/uploads/' + image.name} style={{ height: 35, width: 50}}/>
                <span style={{paddingLeft: 20, fontWeight: 500}}>{userConstants.URL_API + '/uploads/' + image.name}</span>
                <span style={{position: 'absolute', right: 0, cursor: 'pointer'}} onClick={() => this.removeFile(image.name, 'image')}>
                    <i className="material-icons left" style={{fontSize : '1.2rem', color: '#ee1e1e'}}>delete</i>
                </span>
            </div>
        )
    }

    setImageCover(imageName){

        this.setState({
            imageFiles : [...this.state.imageFiles].map(image => { 
                image.cover = image.name === imageName && !image.cover
                return image;
            })
        })
    }

    generateDocumentsRows(){

        return this.state.docs.map(doc => 
            <div style={{display: 'flex', alignItems: 'center'}}>
                               
                <span style={{paddingLeft: 20, fontWeight: 500}}>{userConstants.URL_API + '/uploads/' + doc.name}</span>
                <span style={{position: 'absolute', right: 0, cursor: 'pointer'}} onClick={() => this.removeFile(doc.name, 'doc')}>
                    <i className="material-icons left" style={{fontSize : '1.2rem', color: '#ee1e1e'}}>delete</i>
                </span>
            </div>
        )

    }

    removeFile = async (id, type) => {
        let token = localStorage.getItem(userConstants.MEIS_EDU_TOKEN);
        await this.setState({uploadLoading: true});

        axios({
            method: 'post',
            url: '/api/removeFile',
            data: {},
            params: { 
                file_id: id, 
                news_id : this.props.data.id,
                type
            },
            headers: {
                Authorization: `Bearer ${token}` 
            }
            })
            .then( (response) => {
                this.setState({uploadLoading: false});

                if(response.data.msg === 'ok'){

                    if(type == 'image'){
                        this.setState({ 
                            imageFiles : this.state.imageFiles.filter(( image ) => image.name !== id)
                        });
                    }else {
                        this.setState({ 
                            docs : this.state.docs.filter(( doc ) => doc.name !== id)
                        });
                    }
                   
                }
            })
            .catch( (err) => {
                this.setState({uploadLoading: false});
                //console.log(err);
        });
    }

    generateImageSection(){
        if(this.props.type != 1) return null;

        return (
            <div className="row " style={{marginTop: 50, maxWidth: 1000, position: 'relative'}}>
                <div className="table-container">
                    <div className="table-header" style={{display: 'flex', alignItems: 'center'}}>
                        <span>{labelService.getLabel(this.props.selectedLanguage, 'PHOTOS')}</span>
                        <span onClick={() => this.fileInput4.click()} style={{position: 'absolute', right: 0, fontWeight: 'bold', fontSize: 17, cursor: 'pointer'}}>
                            <i className="material-icons left" style={{fontSize : '1.6rem', color: '#19c539'}}>add_circle</i>
                        </span>
                    </div>
                    <div className="table-content">
                        {this.generateImagesRows()}
                    </div>
                </div>
            </div>
        )
    }

    handleChangeDate = date => {
        this.setState({
            dateBegin: date,
        });
    }

    render() {
        const {  main_post, post_public, uploadLoading } = this.state;
        const imageSection = this.generateImageSection();

        return (
            <div className="main-content" >
                {uploadLoading &&
                    <Spiner></Spiner>
                }
                <input 
                    className="hide_file"
                    ref={fileInput => this.fileInput4 = fileInput}
                    style={{cursor: 'pointer', width: 0, height: 0}}
                    type="file"
                    name="user[image]"
                    multiple={true}
                    onChange={(e) => this.onChooseImage(e)}/>
                <input 
                    className="hide_file"
                    ref={fileInput => this.fileInput = fileInput}
                    style={{cursor: 'pointer', width: 0, height: 0}}
                    type="file"
                    name="user[image]"
                    multiple={true}
                    onChange={(e) => this.onChooseDoc(e)}/>
                
                <div className="row" >
                    <button className="btn waves-effect waves-light" type="button" name="back" style={{marginTop: 20, }} onClick={this.props.onGoBack}>
                        BACK
                        <i className="material-icons left" style={{fontSize : '1.6rem'}}>keyboard_backspace</i>
                    </button>
                </div>
                <div className="row" style={{marginRight: 50, marginTop: 50}}>
                    <div className="col s12">
                        {(this.props.type === 1 || this.props.type === 5 || this.props.type === 2) &&
                            <>
                                <div className="row " style={{ maxWidth: 420}}>
                                    <div className="input-field col s12 mtp0">
                                        <input id="post_title" type="text" name="post_title" value={this.state.post_title} onChange={this.handleChange}/>
                                        <label htmlFor="post_title">{labelService.getLabel(this.props.selectedLanguage, 'TITLE')}</label>
                                    </div>
                                </div>
                                <div className="row " style={{ maxWidth: 420}}>
                                    <div className="input-field col s12 mtp0">
                                        <input id="post_title_eng" type="text" name="post_title_eng" value={this.state.post_title_eng} onChange={this.handleChange}/>
                                        <label htmlFor="post_title_eng">{labelService.getLabel(this.props.selectedLanguage, 'TITLE_ENG')}</label>
                                    </div>
                                </div>
                            </>
                        }
                        <div className="row " style={{ maxWidth: 1000, marginTop: 44}}>
                            <div style={{paddingBottom: 20}}>
                                <span style={{fontSize: 28}}>{labelService.getLabel(this.props.selectedLanguage, 'POST_TEXT')}</span>
                            </div>
                            <Editor
                                editorState={this.state.editorState}
                                handlePastedText={() => false}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={this.onEditorStateChange}
                                />
                        </div>
                        <div className="row " style={{ maxWidth: 1000, marginTop: 44}}>
                            <div style={{paddingBottom: 20}}>
                                <span style={{fontSize: 28}} >{labelService.getLabel(this.props.selectedLanguage, 'POST_TEXT_ENG')}</span>
                            </div>
                            <Editor
                                editorState={this.state.editorStateEng}
                                handlePastedText={() => false}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={this.onEditorStateEngChange}
                                />
                        </div>
                        {imageSection}
                        {this.props.type !== 5
                        && <div className="row " style={{marginTop: 50, maxWidth: 1000, position: 'relative'}}>
                            <div className="table-container">
                                <div className="table-header" style={{display: 'flex', alignItems: 'center'}}>
                                    <span>{labelService.getLabel(this.props.selectedLanguage, 'DOCUMENTS')}</span>
                                    <span onClick={() => this.fileInput.click()} style={{position: 'absolute', right: 0, fontWeight: 'bold', fontSize: 17, cursor: 'pointer'}}>
                                        <i className="material-icons left" style={{fontSize : '1.6rem', color: '#19c539'}}>add_circle</i>
                                    </span>                                
                                </div>
                                <div className="table-content">
                                {this.generateDocumentsRows()}
                                </div>
                            </div>
                        </div>}
                        <div className="row frame" style={{marginTop: 40, zIndex: 3000, position: 'relative'}}>
                            <div className="input-field">
                                <span className="multiselect-label">{labelService.getLabel(this.props.selectedLanguage, 'PUBLISH_DATE')}</span>
                                <div>
                                    <DatePicker
                                        selected={this.state.dateBegin}
                                        showTimeSelect
                                        onChange={this.handleChangeDate}
                                        locale={this.props.selectedLanguage == 'ENG' ? 'en' : 'sr-Latn'}
                                        dateFormat="HH:mm dd/MM/yyyy"
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="row card" style={{marginTop: 50, maxWidth: 1000, padding: 20}}>
                            <div className="col s6 center">
                                <label>
                                    <input 
                                        type="checkbox" 
                                        checked={post_public} 
                                        onChange={() => this.setState({ post_public : !post_public})} />
                                    <span>{labelService.getLabel(this.props.selectedLanguage, 'PUBLIC')}</span>
                                </label>
                            </div>
                            <div className="col s6 center" style={{borderLeft: '1px solid gray'}}>
                                {this.props.type === 1 && 
                                    <label>
                                        <input 
                                            type="checkbox" 
                                            checked={main_post} 
                                            onChange={() => this.setState({ main_post : !main_post})} />
                                        <span>{labelService.getLabel(this.props.selectedLanguage, 'COVER')}</span>
                                    </label>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row " style={{ marginTop: 40}}>
                    <div className="input-field col s12 mtp0" style={{ display: 'flex'}}>
                        <a  
                            href="javascript:void(0)" 
                            className="waves-effect waves-light btn darken-4"
                            onClick={this.updatePost}
                            >
                            {labelService.getLabel(this.props.selectedLanguage, 'SAVE')}
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default PostForm;
