import axios from 'axios';
import React, { Component } from 'react';
import { Header } from '../components/MainHeader/MainHeader';
import { MiniHeader } from '../components/MiniHeader/MiniHeader';
import { connect } from 'react-redux';
import Posts from '../components/Posts';
import {MainFooter} from '../components/MainFooter';

class Documents extends Component {

    constructor(props){
        super(props);

        this.state = {
            main_post : null,
            post_text: '',
            post_title: '',
            imageFile: undefined,
            imageBase64: null,
            uploadLoading: false,
            sideNavSelected: 0
        }
    }

    render() {

        return (
            <div className="home-root">
                <MiniHeader history={this.props.history}/>
                <Header />
                <div className="home-main" style={{ paddingTop: 100}}>
                    <div className="container">
                        <Posts selectedLanguage={this.props.selectedLanguage}  name="Odluke" type={3}  history={this.props.history} />
                    </div>
                </div>
                <MainFooter/>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { loggedIn, user } = state.authentication;
    const selectedLanguage = state.language;
    return {
        loggedIn, user, selectedLanguage
    };
}


const connectedDocuments = (connect(mapStateToProps)(Documents));
export {connectedDocuments as Documents};
