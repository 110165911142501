import React, { Component } from 'react';
import { connect } from 'react-redux';
import labelService from '../labelService';
import { userConstants } from '../_constants/user-constants';
import vis_img_eng from '../assets/vis_eng.png'
import vis_img_mne from '../assets/vis_mne.png'

class MainFooter extends Component{ 
	constructor(props){
        super(props);
    }

	render(props) {
	    return (
	    	<div className="main-footer-container">
		    	<div className="main-footer-visibility">
		    	<div className="row centered-container">
		    		<div className="col m6">
					    <div style={{maxWidth : 800, textAlign: 'center', margin : 10}} dangerouslySetInnerHTML={{__html:labelService.getLabel(this.props.selectedLanguage, 'VISIBILITY')}}/>
					    <div style={{maxWidth : 800, textAlign: 'center', margin : 10}}>{labelService.getLabel(this.props.selectedLanguage, 'DISCLAIMER')}</div>
				    </div>
				    <div className="col m6">
				    	{this.props.selectedLanguage === 'MNE' &&
				    		<a href="https://pjp-eu.coe.int/en/web/horizontal-facility" target="_blank">
				    			<img src={vis_img_mne} style={{minHeight: 10, maxWidth: 600}}/>
				    		</a>
				    	}
				    	{this.props.selectedLanguage === 'ENG' &&
				    		<a href="">
				    		<img src={vis_img_eng} style={{minHeight: 10, maxWidth: 600}}/>
				    		</a>
				    	}
				    </div>
				</div>
				</div>
		       	<div className="main-footer">
				    <div>{"Copyright © Ministarstvo prosvjete, nauke, kulture i sporta 2021"}</div>
				</div>
			</div>
	    )
	}
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.authentication;
    const selectedLanguage = state.language;
    return {
        loggedIn, user, selectedLanguage
    };
}


const connectedMainFooter = (connect(mapStateToProps)(MainFooter));
export {connectedMainFooter as MainFooter};

