export const MneLabels = {
    MAIN_TITLE                          : 'Etički komitet',
    MAIN_SUBTITLE                       : 'Misija Etičkog komiteta je unapređenje, zaštita i promocija akademskog integriteta i prevencija od svih oblika kršenja akademskog integriteta',
    HOME                                : 'Početna',
    ABOUT                               : 'O etičkom komitetu',
    NEWS                                : 'Vijesti',
    DECISIONS                           : 'Odluke',
    DOCUMENTS                           : 'Dokumenta',
    SAZIVI                              : 'Sazivi',
    EXTERN_LINKS                        : 'Eksterni linkovi',
    PUBLISH_DATE                        : 'Datum objave',
    NO_DOCUMENTS                        : 'Nema dokumenata',
    DOWNLOAD                            : 'Preuzmi',
    ADD_NEWS                            : 'Dodaj Vijest',
    ADD_CONGRESS                        : 'Dodaj saziv',
    TITLE                               : 'Naslov',
    TITLE_ENG                           : 'Naslov na engleskom',
    POST_TEXT                           : 'Tekst novosti',
    POST_TEXT_ENG                       : 'Tekst novisti na engleskom',
    PHOTOS                              : 'Slike',
    PUBLIC                              : 'Objavi',
    COVER                               : 'Naslovna',
    LATEST_NEWS                         : 'Posljednje vijesti',
    SHOW_MORE                           : 'Prikaži više',
    PREVIUOS                            : 'Prethodna',
    NEXT                                : 'Sledeća',
    SEARCH                              : 'Pretraga',
    LOGIN                               : 'Prijavi se',
    LOGOUT                              : 'Odjavi se',
    SAVE                                : 'Sačuvaj',
    CHOOSE_IMAGE                        : 'Izaberi sliku',
    ABOUT1_TITLE                        : "Etički komitet",
    ABOUT1_TEXT                         : "Etički komitet radi na očuvanju, unapređenju, zaštiti i promociji akademskog integriteta i prevenciji od svih oblika kršenja akademskog integriteta. Etički komitet imenuje i razrješava Vlada Crne Gore na period od četiri godine na prijedlog organa državne uprave nadležnog za poslove prosvjete. Etički komitet ima sedam članova koji ĉine istaknuti eksperti iz oblasti visokog obrazovanja i nauke iz različitih naučnih disciplina.",
    ABOUT2_TITLE                        : "Etička povelja",
    ABOUT2_TEXT                         : "Etička povelja je skup pravila, načela i smjernica kojima se promovišu ljudske, intelektualne i akademske slobode, kao i odgovorno ostvarivanje prava u naučnom, nastavnom, umjetničkom i stručnom radu.",
    ABOUT3_TITLE                        : "Odlučivanje Etičkog odbora",
    ABOUT3_TEXT                         : "O kršenju akademskog integriteta odlučuje Etički odbor. Prijedlog za utvrđivanje kršenja akademskog integriteta može da podnese svako fizičko ili pravno lice. Prijedlog sa obrazloženjem, podnosi se Etičkom odboru ustanove na kojoj je odbranjen rad, stečena kvalifikacija, odnosno počinjen drugi oblik kršenja akademskog integriteta. Etički odbor obrazuje komisiju od tri člana iz iste ili srodne naučne oblasti. Komisija sačinjava izvještaj sa prijedlogom koji Etički odbor dostavlja na izjašnjavanje licu protiv koga je pokrenut postupak. Rok za izjašnjavanje lica protiv kojeg je pokrenut postupak na izvještaj sa prijedlogom je 30 dana. Ako se lice ne izjasni u prediđenom roku, Etički odbor donosi odluku.",
    VISIBILITY                          : 'Ovaj website je izrađen uz pomoć sredstava zajedničkog projekta Evropske unije i Savjeta Evrope <a href="https://rm.coe.int/hf24-leaflet-eng/16809a5a59" target="_blank">“Kvalitetno obrazovanje za sve”</a>, koji se sprovodi od 2019. do 2022. godine.',
    DISCLAIMER                          : 'Mišljenja i stavovi izraženi ovdje ne izražavaju nužno zvanične stavove Evropske unije i Savjeta Evrope.',
};