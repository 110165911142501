import React, { Component } from 'react'
import  { connect } from 'react-redux';
import { userConstants } from "../../_constants/user-constants";
import {Link} from 'react-router-dom';
import { failure} from '../../_actions/actions';
import  { selectLanguage } from '../../_actions/languageActions';
import mne from './mne.png'
import eng from './eng.png'
import labelService from '../../labelService';

class MiniHeader extends Component {

    constructor(props){
        super(props);
        this.dropDown = React.createRef();
        this.state = {
            icon_name: 'mne',
            username: '',
            loggedIn: false,
            isAdmin: false
        }
    }

    componentDidMount() {
        const M = window.M;
        let language = localStorage.getItem(userConstants.LANGUAGE_LOCAL);
        this.setState({ icon_name : language ? language.toLowerCase(): 'mne'});

        if(language && language !== this.props.selectedLanguage)
            this.props.selectLanguage(language);

        let dropdowns = document.querySelectorAll('.dropdown-trigger');
    
        let options = {
            inDuration: 300,
            outDuration: 300,
            hover: false, 
            coverTrigger: false,
        };
        M.Dropdown.init(dropdowns, options);
        /*if (language && language !== this.props.selectedLanguage && this.props && this.props.history)
            this.props.history.push("/");*/

        this.loadUser();
    }

    loadUser() {
        const data = JSON.parse(localStorage.getItem(userConstants.USER_DATA));
        if(!data) return;
        if( !data.isAdmin) {
            this.setState({ username : data.username, loggedIn : true, isAdmin: false});
        }else {
            this.setState({ username : data.username, loggedIn : true, isAdmin: true});
        }

    }
    
    selectChange = async (e) => {
        const name = e.currentTarget.name;
        localStorage.setItem(userConstants.LANGUAGE_LOCAL, name);
        window.location.reload(false);
    }

    onLogOut = (e) => {
        localStorage.clear()
        this.setState({ username : '', loggedIn : false});
    }

    getLangIcon(){
        if(this.state.icon_name === 'mne') return mne;
        else return eng;
    }

    render() {

        return (
            <div className="mini-header" style={{zIndex: 5555, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: 50, backgroundColor: '#009688'}}>
                <div className="right-container" >
                    <div className="filter-group right-container" style={{ position : 'relative', padding : 0, paddingRight: 20}}>
                        <div data-target='dropdown1' className='left dropdown-trigger' > <a  style={{ height : 20}}><img src={this.getLangIcon()} width="25"/></a></div>
                        <div style={{ marginRight: 10}}>
                            <ul id='dropdown1' ref={this.myRef} className='dropdown-content' style={{minWidth : 180}} >
                                <li><a href="javascript:void(0)" name="MNE" onClick={ (e) => this.selectChange(e)}><img src={mne} width="25"/><span style={{ paddingLeft : 20}}>Crnogorski</span></a></li>
                                <li><a href="javascript:void(0)" name="ENG" onClick={ (e) => this.selectChange(e)}><img src={eng} width="25" /><span style={{ paddingLeft : 20}}>English</span></a></li>
                            </ul>
                        </div>
                        {this.state.loggedIn && <Link to="/admin" className="header-username hide-on-med-and-down openSansHeader" style={{ color: 'white', textDecoration: 'underline'}}>{this.state.username}</Link>}
                        {this.state.loggedIn ? 
                            <Link onClick={this.onLogOut} to="/login" style={{ borderRadius: 4, border: '0.5px solid gray', color: '#fff',backgroundColor: '#615fc9', fontWeight: 'bold', marginLeft: 10, paddingRight: 4,paddingLeft: 4, fontSize: 11, padding: 3}}>
                                {labelService.getLabel(this.props.selectedLanguage, 'LOGOUT')}
                                
                            </Link>
                            :
                            <Link to="/login" style={{ borderRadius: 4, border: '0.5px solid gray', color: '#fff',backgroundColor: '#009688', fontWeight: 'bold', marginLeft: 10, paddingRight: 4,paddingLeft: 4, fontSize: 11, padding: 3}}>
                                {labelService.getLabel(this.props.selectedLanguage, 'LOGIN')}

                            </Link>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loggedIn: state.authentication.loggedIn,
    username: state.authentication.user, 
    selectedLanguage: state.language,
});


const connectedHeader = (connect(mapStateToProps, { selectLanguage, failure })(MiniHeader));
export {connectedHeader as MiniHeader};