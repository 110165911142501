import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header } from '../components/MainHeader/MainHeader';
import { MiniHeader } from '../components/MiniHeader/MiniHeader';
import labelService from '../labelService';
import axios from 'axios'
import { failure, success } from '../_actions/actions';
import { userConstants } from '../_constants/user-constants';

class Login extends Component {

    constructor(props){
        super(props);

        this.state = {
            username: '',
            password: '',
            badLoginMsg: '',
            badLogin: false,
            loading : false,
            register: false,
            send_password : false
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }


    handleSubmit = async (e) => {
        e.preventDefault();
        
        const { username, password, mail } = this.state;
        const { dispatch } = this.props;
        await this.setState({ loading: true });
        let user  = {'username' : mail, 'pass' : password, 'valid' : '10d', 'lang' : this.props.selectedLanguage};

        axios.post('/api/login', user)
        .then(response => {
            let server_data = response.data;
            this.setState({ loading: false});
            if (server_data.msg == ''){
                //store token
                localStorage.setItem(userConstants.MEIS_EDU_TOKEN, server_data.idToken);
                
                let user_data = { username : user.username, id : server_data.id, isAdmin : server_data.isAdmin}
                localStorage.setItem(userConstants.USER_DATA, JSON.stringify(user_data));

                this.setState({ badLogin : false, badLoginMsg : ''});
                dispatch(success(mail));


                //check if it is admin
                if(server_data.isAdmin)
                    this.props.history.push("/admin");
                else
                    this.props.history.push("/");
            }
            else {
            	this.setState({ badLogin : true, badLoginMsg : server_data.msg});
                dispatch(failure());
            }
        }
        ,error => {
        	this.setState({ loading: false, badLogin : true, badLoginMsg : 'Došlo je do greške prilikom prijavljivanja'});
            dispatch(failure());
        });
    }


    render() {
        const {badLoginMsg, badLogin } = this.state;

        return (
            <div>
                <MiniHeader history={this.props.history}/>
                <Header/>
                <div className="container" style={{paddingTop: 180, height: 2000}}>
                    <div className="form-container row">
                        <form className=" col s12" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 100}}>
                            {badLogin &&
                                <div className="centered-container" style={{marginBottom : 20}}>
                                    <div className="lbl-err-txt">{badLoginMsg}</div>
                                </div>
                            }
                            <div className="row mlp0" style={{ width: 420}}>
                                <div className="input-field col s12 mtp0">
                                    <input id="mail" type="text" name="mail" onChange={this.handleChange}/>
                                    <label htmlFor="mail">{labelService.getLabel(this.props.selectedLanguage, 'SCC_USERNAME')}</label>
                                </div>
                            </div>
                            <div className="row mlp0" style={{ width: 420}}>
                                <div className="input-field col s12 mtp0">
                                    <input id="password" type="password" name="password" onChange={this.handleChange}/>
                                    <label htmlFor="password">{labelService.getLabel(this.props.selectedLanguage, 'SCC_PASSWORD')}</label>
                                </div>
                            </div>
                            <div className="row mlp0" style={{ width: 420}}>
                                <div className="input-field col s12 mtp0" style={{ display: 'flex', justifyContent: 'center'}}>
                                    <a href="javascript:void(0)" className="waves-effect waves-light btn darken-4"
                                        onClick={this.handleSubmit}>{labelService.getLabel(this.props.selectedLanguage, 'LOGIN')}</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.authentication;
    const selectedLanguage = state.language;
    return {
        loggedIn, user, selectedLanguage
    };
}


const connectedLogin = (connect(mapStateToProps)(Login));
export {connectedLogin as Login};

