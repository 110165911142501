import axios from 'axios';
import React, { Component } from 'react'
import { userConstants } from '../../_constants/user-constants';
import InnerHtmlDiv from '../InnerHtmlDiv';
import PostForm from './PostForm';
import ReactPaginate from 'react-paginate';
import Spiner from '../Spiner/Spiner';
import moment from 'moment'
import labelService from '../../labelService';
import CongressForm from './CongressForm';
export default class Posts extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading : false,
            news: [],
            edit: false,
            selected_news : null,
            perPage : 10,
            offset: 0,
            pageCount: 0,
            type: 1,
            search: ''
        }
    }

    async componentDidMount(){
        await this.getAllCongress();
    }

    async componentDidUpdate(prevsta){
        if(this.state.type != this.props.type){
            await this.setState({ edit : false, type: this.props.type});
            this.getAllCongress();
        }
    }

    async getAllCongress(){

        await this.setState({ loading: true});

        axios.post('/api/getAllCongress', {limit: this.state.perPage, offset: this.state.offset, search: this.state.search})
        .then(response => {
            let server_data = response.data;
            this.setState({ loading: false});

            if(server_data.msg === 'succes'){
                setTimeout(() => {
                    this.setState({
                        news : server_data.news,
                        pageCount: server_data.total_count / this.state.perPage,
                    });
                }, 100);
            }
        }
        ,error => {
            this.setState({ loading: false});
        });
    }

    createNewCongress = async () => {
        let token = localStorage.getItem(userConstants.MEIS_EDU_TOKEN);

        axios({
            method: 'post',
            url: '/api/createNewCongress',
            data: {},
            params: {},
            headers: {
                Authorization: `Bearer ${token}` 
            }
            })
            .then( async  (response) => {
                let server_data = response.data;
                if(server_data.msg === 'succes'){
                    await this.setState({
                        selected_news: {id : server_data.congress_id},
                        edit: true,
                     });
                 }
            })
            .catch( (err) => {
        });
    }

    generateMainContent(){
        return this.state.edit ?
            <CongressForm  
                data={this.state.selected_news} 
                selectedLanguage={this.props.selectedLanguage}
                onGoBack={this.closeNews}
                type={this.state.type}
                /> 
            : 
            this.generateRows()
    }

    generateRows(){
        const { news } = this.state;
        return (
            <div style={{paddingTop: 20}}>
                <div className="row" style={{ maxWidth: 420}}>
                    <div className="input-field col s8 mtp0">
                        <input id="search" type="text" name="search" value={this.state.search} onChange={(e) => { this.setState({ search : e.target.value})}}/>
                        <label htmlFor="search">{labelService.getLabel(this.props.selectedLanguage, 'SEARCH')}</label>
                        
                    </div>
                    <div className="input-field col s4 mtp0" style={{paddingTop: 5}}>
                        <a  
                            href="javascript:void(0)" 
                            className="waves-effect waves-light btn darken-4"
                            onClick={(e) => { this.getAllCongress() } }
                            >
                            {labelService.getLabel(this.props.selectedLanguage, 'SEARCH')}
                        </a>
                    </div>
                    <div style={{ position: 'fixed', right: 30}}>
                        <div className="input-field" >
                            <a  
                                href="javascript:void(0)" 
                                className="waves-effect waves-light btn darken-4"
                                style={{width: 200}}
                                onClick={this.createNewCongress}
                                >
                                    {labelService.getLabel(this.props.selectedLanguage, 'ADD_CONGRESS')}
                                
                                <i className="material-icons left" style={{fontSize : '1.5rem', color: '#fff'}}>add</i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col" style={{width: '100%', paddingRight: 400, minHeight: 600}}>
                        {news.map(news_item => 
                            <div className="row card post-card" onClick={(e) => this.openNews(e, news_item)} style={{ padding: 20, minHeight: 150}}>
                                <a className="post-title"  style={{fontSize: 20}}>
                                    {this.generateTitle(news_item)}
                                </a>
                                <div style={{padding: 15}}>
                                    <InnerHtmlDiv className="" html_text={this.props.selectedLanguage === 'MNE' ? this.converHtmlToText(news_item.text) : this.converHtmlToText(news_item.text_eng) }/>
                                </div>
                                <span style={{ position: 'absolute', right: 10, top: 10, fontSize: 12, fontWeight : 'bold', color: '#009688'}}>
                                     {news_item.publish &&
                                        <>
                                            <span style={{color: 'gray'}} className="left">objavljena</span>
                                            <i className="material-icons left" style={{fontSize : '1.5rem', color: '#00a8ff'}}>check_circle</i>
                                        </>                                      
                                     }
                                    {moment(parseInt(news_item.dateunix) ).format('HH:mm DD/MM/YYYY')}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="row center" style={{width: '100%', paddingRight: 400}}>
                    <ReactPaginate
                        previousLabel={labelService.getLabel(this.props.selectedLanguage,'PREVIUOS')}
                        nextLabel={labelService.getLabel(this.props.selectedLanguage,'NEXT')}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={8}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        />
                </div>
            </div>
            
        )
    }

    converHtmlToText(html){
        if(!html) return '-';
        var text = html.replace(/<[^>]+>/g, '');

        return text.substring(0,300);
    }

    handlePageClick = async (data) => {
        let selected = data.selected;
        let offset = Math.ceil(selected * this.state.perPage);
        await this.setState({ offset});

        this.getAllCongress();
    }

    generateTitle(news_item){
        const { selectedLanguage } = this.props;

        if(selectedLanguage === 'MNE') return news_item.title || 'Prazno';
        else return news_item.title_eng || 'Empty';
    }
    
    openNews = async (e, news_item) => {
        e.preventDefault();
        await this.setState({ edit: true, selected_news: news_item});
    }

    closeNews = async() => {
        await this.setState({ edit: false, selected_news: []});

        this.getAllCongress();
    }

    render() {
        const content = this.generateMainContent();
        return (
            <div>
                 {this.state.loading &&
                    <Spiner></Spiner>
                }
                {content}
            </div>
        )
    }
}
