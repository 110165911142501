import axios from 'axios';
import React, { Component } from 'react';
import { Header } from '../components/MainHeader/MainHeader';
import { MiniHeader } from '../components/MiniHeader/MiniHeader';
import labelService from '../labelService';
import { userConstants } from '../_constants/user-constants';
import { connect } from 'react-redux';
import imageCompression from 'browser-image-compression';
import MainPostForm from '../components/Admin/PostForm';
import Posts from '../components/Admin/Posts';
import {MainFooter} from '../components/MainFooter';
import Congress from '../components/Admin/Congress';

const sideNavList = ['NEWS', 'DECISIONS', 'DOCUMENTS', 'SAZIVI', 'EXTERN_LINKS'];
class Admin extends Component {

    constructor(props){
        super(props);

        this.state = {
            main_post : null,
            post_text: '',
            post_title: '',
            imageFile: undefined,
            imageBase64: null,
            uploadLoading: false,
            sideNavSelected: 0,
        }
    }

    componentDidMount(){
        this.loadUser();
    }
    
    loadUser() {
        const data = JSON.parse(localStorage.getItem(userConstants.USER_DATA));
        if( !data || !data.isAdmin) this.props.history.push('/');
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onChooseQuestionImage = (event) => {
        let her = this;

        if (event.target.files && event.target.files[0]) {
            let file = event.target.files[0];
            this.setState({ imageFile : file});
            
            var reader = new FileReader();
            reader.onloadend = function() {
                her.setState({ imageBase64: reader.result});
            }
            reader.readAsDataURL(file);
        }
    }

    uploadMainPost = async () => {
        let { main_post, imageFile, post_title} = this.state; 
        let token = localStorage.getItem(userConstants.MEIS_EDU_TOKEN);

        if(!imageFile) {
            alert('Niste izabrali sliku');
            return ;
        }

        if(!post_title) {
            alert('Morate unijeti naslov');
            return;
        }

        imageFile = await this.compressImage(imageFile);

        const data = new FormData();
        data.append('files', imageFile);
        await this.setState({uploadLoading: true});

        axios({
            method: 'post',
            url: '/api/uploadMainPost',
            data: data,
            params: { imageName : imageFile.name, post_title},
            headers: {'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` }
            })
            .then( (response) => {
                //handle success
                this.setState({uploadLoading: false});
                if(response.data.msg === 'ok'){
                    
                }
            })
            .catch( (err) => {
                this.setState({uploadLoading: false});
                //handle error
                //console.log(err);
        });
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    sideNavOnSelect = async (index) => {
        await this.setState({ sideNavSelected : index});
    }

    generateRightComponent(){

        const { sideNavSelected } = this.state;

        switch (sideNavSelected) {
            case 0: return <Posts selectedLanguage={this.props.selectedLanguage}  name="Vijesti" type={sideNavSelected + 1}   />;
            case 1: return <Posts selectedLanguage={this.props.selectedLanguage}  name="Odluke"  type={sideNavSelected + 1}   />;
            case 2: return <Posts selectedLanguage={this.props.selectedLanguage}  name="Dokumenta" type={sideNavSelected + 1} />;
            case 3: return <Congress selectedLanguage={this.props.selectedLanguage}  name="Sazivi" type={sideNavSelected + 1} />;
            case 4: return <Posts selectedLanguage={this.props.selectedLanguage}  name="Eksterni linkovi" type={sideNavSelected + 1} />;
                
            default:
                return null;
        }
    }

    render() {

        return (
            <div className="home-root">
                <MiniHeader />
                <Header />
                <div className="home-main" style={{ paddingTop: 100, position: 'relative'}}>
                    <div className="row" style={{position: 'relative', display: 'flex'}}>
                       <div className="side-nav-con col" style={{ borderRight: '1px solid #eeeeee', position: 'fixed', minWidth: 270, top: 70, height: '100vh', paddingTop: 90}}>
                           <ul className="admin-nav-list" style={{ }}>
                               {sideNavList.map((item, index) => 
                                    <li onClick={() => this.sideNavOnSelect(index)} className={index === this.state.sideNavSelected ? 'selected-item' : ''}>
                                        {labelService.getLabel(this.props.selectedLanguage, item)}
                                    </li>
                               )}
                           </ul>
                       </div>
                       <div className="col" style={{marginLeft: 270, width: '100%', paddingLeft: 60}}>
                           {this.generateRightComponent()}
                       </div>
                    </div>
                </div>
                <MainFooter/>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { loggedIn, user } = state.authentication;
    const selectedLanguage = state.language;
    return {
        loggedIn, user, selectedLanguage
    };
}


const connectedAdmin = (connect(mapStateToProps)(Admin));
export {connectedAdmin as Admin};
