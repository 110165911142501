import axios from 'axios';
import React, { Component } from 'react';
import labelService from '../../labelService';
import { userConstants } from '../../_constants/user-constants';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {stateFromHTML} from 'draft-js-import-html';
import Spiner from '../Spiner/Spiner';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

class CongressForm extends Component {
    constructor(props){
        super(props);

        this.state = {
            main_post : false,
            title: '',
            title_eng: '',
            publish: false,
            imageFiles: [],
            editorState : EditorState.createEmpty(),
            editorStateEng : EditorState.createEmpty(),
            uploadLoading: false,
            dateBegin: new Date()
        }
    }

    async componentDidMount(){
        const M = window.M;
        
        let elem = document.querySelectorAll('.modal');
        M.Modal.init(elem[0], {startingTop : '50%'});

        await this.getPostData();
       // await this.getImagesAndDocs();
        M.updateTextFields();
    }

    async getPostData(){
        const { data} = this.props;
        if(!data || !data.title) return;

        const contentBlock = htmlToDraft(data.text);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
          

        await this.setState({
            title: data.title,
            title_eng: data.title_eng,
            editorState : editorState, 
            editorStateEng: EditorState.createWithContent(stateFromHTML(data.text_eng)),
            publish: data.publish,
        });
    }

    /*async getImages(){
        const { data } = this.props;
        if(!data) return;

        axios.post('/api/getCongressImages', { congress_id: data.id, type: this.props.type })
        .then(response => {
            let server_data = response.data;
            this.setState({ loading: false});

            if(server_data.msg === 'succes'){
               this.setState({
                    imageFiles: server_data.images,
                });
            }
        }
        ,error => {
        });

    }*/

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    }

    onEditorStateEngChange = (editorStateEng) => {
        this.setState({
            editorStateEng,
        });
    }

    updatePost = async () => {
        let { title, editorState, editorStateEng, title_eng, main_post, publish, imageFiles, dateBegin} = this.state; 
        let token = localStorage.getItem(userConstants.MEIS_EDU_TOKEN);
        await this.setState({uploadLoading: true});

        const editorHTML = this.getContentHtml(editorState);;
        const editorEngHTML = this.getContentHtml(editorStateEng);
        const coverImage = imageFiles.filter(img => img.cover);

        axios({
            method: 'post',
            url: '/api/updateCongress',
            data: {},
            params: { 
                id : this.props.data.id,
                title, 
                title_eng, 
                editorHTML,
                editorEngHTML,
                publish: publish ? 1 : 0,
                dateunix: moment(dateBegin).unix() * 1000
            },
            headers: {
                Authorization: `Bearer ${token}` 
            }
            })
            .then( (response) => {
                if(response.data.msg === 'ok'){
                    setTimeout(() => {
                        this.setState({uploadLoading: false});
                        this.props.onGoBack();
                    }, 250);
                    
                }
            })
            .catch( (err) => {
                this.setState({uploadLoading: false});
                //console.log(err);
        });
    }

    getContentHtml(editorState){
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const markup = draftToHtml(
            rawContentState, 
            {
                trigger: '#',
                separator: ' ',
              }, 
            true, 
        );
        
        return markup;
    }

    onChooseImage = async (event) => {
        let files = event.target.files;
        let hasCoverImage = this.state.imageFiles && this.state.imageFiles.length > 0;

        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            await this.readImageFile(file, !hasCoverImage && i == 0);
        }
      
        this.uploadImages(hasCoverImage);
    }

    readImageFile = (file, isCover) => new Promise(res => {
        let reader = new FileReader();
        const newFileName = Date.now() + '_' + file.name;

        reader.onloadend =  () => {
            const imageBase64 =  reader.result;
            this.setState({
                imageFiles: [
                    ...this.state.imageFiles,
                    { 
                        imageFile: this.renameFile(file, newFileName), 
                        imageBase64, 
                        name: newFileName,
                        upload: true,
                        cover : isCover
                    }
                ]
            });
            res();
        }
        reader.readAsDataURL(file);
    })

    renameFile(originalFile, newName) {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }

    uploadImages = async () => {
        let { imageFiles } = this.state;
        let token = localStorage.getItem(userConstants.MEIS_EDU_TOKEN);

        const data = new FormData();
        imageFiles.forEach(async img => img.upload && data.append("files", img.imageFile));

        await this.setState({
            uploadLoading: true,
            imageFiles : [...imageFiles].map(image => { 
                image.upload = false;
                return image;
            })
        });

        axios({
            method: 'post',
            url: '/api/uploadImages',
            data: data,
            params: { 
                hasCoverImage : false
            },
            headers: {
                'Content-Type': 'multipart/form-data', 
                Authorization: `Bearer ${token}` 
            }
            })
            .then( (response) => {
                this.setState({uploadLoading: false});

                if(response.data.msg === 'ok'){
                    setTimeout(() => {
                        this.setState({ uploadLoading: false });
                    }, 250);
                }
            })
            .catch( (err) => {
                this.setState({uploadLoading: false});
                //console.log(err);
        });
    }

    generateImagesRows(){

        return this.state.imageFiles.map(image =>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <img src={image.imageBase64 ? image.imageBase64 : userConstants.URL_API + '/uploads/' + image.name} style={{ height: 35, width: 50}}/>
                <span style={{paddingLeft: 20, fontWeight: 500}}>{userConstants.URL_API + '/uploads/' + image.name}</span>
            </div>
        )
    }

    setImageCover(imageName){

        this.setState({
            imageFiles : [...this.state.imageFiles].map(image => { 
                image.cover = image.name === imageName && !image.cover
                return image;
            })
        })
    }

    removeImage = async (id, type) => {
        return;
        let token = localStorage.getItem(userConstants.MEIS_EDU_TOKEN);
        await this.setState({uploadLoading: true});

        axios({
            method: 'post',
            url: '/api/removeImage',
            data: {},
            params: { 
                file_id: id, 
                news_id : this.props.data.id,
                type
            },
            headers: {
                Authorization: `Bearer ${token}` 
            }
            })
            .then( (response) => {
                this.setState({uploadLoading: false});

                if(response.data.msg === 'ok'){

                    if(type == 'image'){
                        this.setState({ 
                            imageFiles : this.state.imageFiles.filter(( image ) => image.name !== id)
                        });
                    }else {
                        this.setState({ 
                            docs : this.state.docs.filter(( doc ) => doc.name !== id)
                        });
                    }
                   
                }
            })
            .catch( (err) => {
                this.setState({uploadLoading: false});
        });
    }

    generateImageSection(){
        return (
            <div className="row " style={{marginTop: 50, maxWidth: 1000, position: 'relative'}}>
                <div className="table-container">
                    <div className="table-header" style={{display: 'flex', alignItems: 'center'}}>
                        <span>{labelService.getLabel(this.props.selectedLanguage, 'PHOTOS')}</span>
                        <span onClick={() => this.fileInput4.click()} style={{position: 'absolute', right: 0, fontWeight: 'bold', fontSize: 17, cursor: 'pointer'}}>
                            <i className="material-icons left" style={{fontSize : '1.6rem', color: '#19c539'}}>add_circle</i>
                        </span>
                    </div>
                    <div className="table-content">
                        {this.generateImagesRows()}
                    </div>
                </div>
            </div>
        )
    }

    handleChangeDate = date => {
        this.setState({
            dateBegin: date,
        });
    }

    render() {
        const { imageBase64, main_post, publish, uploadLoading } = this.state;
        const imageSection = this.generateImageSection();

        return (
            <div className="main-content" >
                {uploadLoading &&
                    <Spiner></Spiner>
                }
                <input 
                    className="hide_file"
                    ref={fileInput => this.fileInput4 = fileInput}
                    style={{cursor: 'pointer', width: 0, height: 0}}
                    type="file"
                    name="user[image]"
                    multiple={true}
                    onChange={(e) => this.onChooseImage(e)}/>
                <div className="row" >
                    <button className="btn waves-effect waves-light" type="button" name="back" style={{marginTop: 20, }} onClick={this.props.onGoBack}>
                        BACK
                        <i className="material-icons left" style={{fontSize : '1.6rem'}}>keyboard_backspace</i>
                    </button>
                </div>
                <div className="row" style={{marginRight: 50, marginTop: 50}}>
                    
                    <div className="col s5" style={{display: 'none'}}>
                        <img src={imageBase64} style={{width: 500, height: 300, borderRadius: 8 ,border: '1px solid gray'}}/>
                        <div className="row " style={{ marginTop: 40}}>
                            <div className="input-field col s12 mtp0" style={{ display: 'flex'}}>
                                <a  
                                    href="javascript:void(0)" 
                                    className="waves-effect waves-light btn darken-4"
                                    onClick={() => this.fileInput4.click()}
                                    >
                                    {labelService.getLabel(this.props.selectedLanguage, 'CHOOSE_IMAGE')}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col s12">
                        <div className="row " style={{ maxWidth: 420}}>
                            <div className="input-field col s12 mtp0">
                                <input id="title" type="text" name="title" value={this.state.title} onChange={this.handleChange}/>
                                <label htmlFor="title">{labelService.getLabel(this.props.selectedLanguage, 'TITLE')}</label>
                            </div>
                        </div>
                        <div className="row " style={{ maxWidth: 420}}>
                            <div className="input-field col s12 mtp0">
                                <input id="title_eng" type="text" name="title_eng" value={this.state.title_eng} onChange={this.handleChange}/>
                                <label htmlFor="title_eng">{labelService.getLabel(this.props.selectedLanguage, 'TITLE_ENG')}</label>
                            </div>
                        </div>
                        <div className="row " style={{ maxWidth: 1000, marginTop: 44}}>
                            <div style={{paddingBottom: 20}}>
                                <span style={{fontSize: 28}}>{labelService.getLabel(this.props.selectedLanguage, 'POST_TEXT')}</span>
                            </div>
                            <Editor
                                editorState={this.state.editorState}
                                handlePastedText={() => false}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={this.onEditorStateChange}
                                />
                        </div>
                        <div className="row " style={{ maxWidth: 1000, marginTop: 44}}>
                            <div style={{paddingBottom: 20}}>
                                <span style={{fontSize: 28}} >{labelService.getLabel(this.props.selectedLanguage, 'POST_TEXT_ENG')}</span>
                            </div>
                            <Editor
                                editorState={this.state.editorStateEng}
                                handlePastedText={() => false}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={this.onEditorStateEngChange}
                                />
                        </div>
                        {imageSection}
                        <div className="row" style={{marginTop: 40, zIndex: 3000, position: 'relative'}}>
                            <div className="input-field">
                                <span className="multiselect-label">{labelService.getLabel(this.props.selectedLanguage, 'PUBLISH_DATE')}</span>
                                <div>
                                    <DatePicker
                                        selected={this.state.dateBegin}
                                        onChange={this.handleChangeDate}
                                        placeholderText={this.props.selectedLanguage == 'ENG' ? 'HH:mm MM/DD/YYYY' : 'HH:mm MM/DD/GGGG'}
                                        locale={this.props.selectedLanguage == 'ENG' ? 'en' : 'sr-Latn'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row card" style={{marginTop: 50, maxWidth: 1000, padding: 20}}>
                            <div className="col s6 center">
                                <label>
                                    <input 
                                        type="checkbox" 
                                        checked={publish} 
                                        onChange={() => this.setState({ publish : !publish})} />
                                    <span>{labelService.getLabel(this.props.selectedLanguage, 'PUBLIC')}</span>
                                </label>
                            </div>
                            <div className="col s6 center" style={{borderLeft: '1px solid gray'}}>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row " style={{ marginTop: 40}}>
                    <div className="input-field col s12 mtp0" style={{ display: 'flex'}}>
                        <a  
                            href="javascript:void(0)" 
                            className="waves-effect waves-light btn darken-4"
                            onClick={this.updatePost}
                            >
                            {labelService.getLabel(this.props.selectedLanguage, 'SAVE')}
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default CongressForm;
